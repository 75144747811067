import { Icon, Link } from "../../index";
import React, { Component } from "react";

import DatePicker from "react-datepicker";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import intl from "react-intl-universal";
import { intlReplace } from "../../../utils/IntlReplace";
import moment from "moment";
import onClickOutside from "react-onclickoutside";
import propTypes from "prop-types";
import {
  toggleFilter,
  parseFilter,
} from "../../../store/modules/RequestFilter";

const mapStateToProps = (state) => ({
  activedFilters: state.RequestFilter.activedFilters,
  filters: state.RequestFilter.filter,
  initialValues: state.RequestFilter.filter,
});

class SearchPeriod extends Component {
  constructor(props) {
    super(props);

    this.state = {
      startDate: moment().subtract(1, "year"),
      endDate: moment(),
      period: "all-dates",
      periodDates: "",
      initialLoad: false,
      clear: true,
      invalidDate: false,
    };

    this.handleRadios = this.handleRadios.bind(this);
    this.handleDate = this.handleDate.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClickOutside() {
    const { toggleFilter, activedFilters } = this.props;
    activedFilters.includes("period") && toggleFilter("period");
  }

  handleClick() {
    const { period } = this.state;
    const { toggleFilter, parseFilter, filters } = this.props;

    if (period !== "all-dates") {
      const inputText = this.inputs.querySelectorAll('input[type="text"]');
      const validStartDate = moment(inputText[0].value, "YYYY-MM-DD").isValid();
      const validEndDate = moment(inputText[1].value, "YYYY-MM-DD").isValid();

      if (validStartDate && validEndDate) {
        this.setState({
          invalidDate: false,
        });
        this.setState(
          {
            periodDates: `${moment(inputText[0].value, "DD/MM/YYYY").format(
              "DD/MM/YY"
            )} - ${moment(inputText[1].value, "DD/MM/YYYY").format(
              "DD/MM/YY"
            )}`,
          },
          () => {
            parseFilter({
              ...filters,
              ...{
                data_inicial: moment(inputText[0].value, "DD/MM/YYYY").format(
                  "YYYY-MM-DD"
                ),
                data_final: moment(inputText[1].value, "DD/MM/YYYY").format(
                  "YYYY-MM-DD"
                ),
              },
            });
          }
        );
        toggleFilter("period");
      } else {
        this.setState({
          invalidDate: true,
        });
      }
    } else {
      this.setState(
        {
          periodDates: "",
        },
        () => {
          parseFilter({ ...filters, ...{ data_inicial: "", data_final: "" } });
        }
      );
      toggleFilter("period");
    }
  }

  handleRadios(e) {
    this.setState({
      period: e.target.value,
    });
  }

  handleDate(type, date) {
    this.setState({
      [type]: date,
    });
  }

  static getDerivedStateFromProps(props, state) {
    const { initialValues } = props;

    if (
      !initialValues.data_inicial &&
      !initialValues.data_final &&
      !state.clear
    ) {
      return {
        startDate: moment().subtract(1, "year"),
        endDate: moment(),
        period: "all-dates",
        periodDates: "",
        initialLoad: false,
        clear: true,
      };
    }

    if (
      !!initialValues.data_inicial &&
      !!initialValues.data_final &&
      !state.initialLoad
    ) {
      const { data_inicial, data_final } = initialValues;

      return {
        startDate: moment(data_inicial, "YYYY/MM/DD"),
        endDate: moment(data_final, "YYYY/MM/DD"),
        initialLoad: true,
        period: "personalized",
        periodDates: `${moment(data_inicial, "YYYY/MM/DD").format(
          "DD/MM/YY"
        )} - ${moment(data_final, "YYYY/MM/DD").format("DD/MM/YY")}`,
        clear: false,
      };
    }

    return null;
  }

  render() {
    const { type, insideLabel, toggleFilter, activedFilters } = this.props;
    const { periodDates, period, startDate, endDate, invalidDate } = this.state;

    return (
      <div
        className={`vg-filter  ${
          activedFilters.includes("period") && "vg-filter-open"
        }`}
      >
        <button
          className="vg-filter-title"
          onClick={() => toggleFilter("period")}
          type="button"
          aria-expanded={activedFilters.includes("period")}
          aria-controls="filter-period"
        >
          {intl.get(`REQUISICAO_DE_VAGAS.${intlReplace(type)}.TITULO`)}:
          <Icon iconKey="arrow" aria-hidden={true} />
          {periodDates !== "" && (
            <span className="vg-filter-smart-placeholder vg-text-limiter">
              {periodDates}
            </span>
          )}
        </button>
        <div
          className="vg-menu-filter vg-sub-menu-filter vg-sub-menu-periodo"
          id="filter-period"
        >
          {insideLabel && (
            <label className="vg-label-title">{insideLabel}</label>
          )}
          <fieldset
            className="vg-custom-radio vg-fieldset-borderless"
            aria-label={intl.get("VAGAS.BUSCA.OPCOES_PERIODO")}
          >
            <label className="vg-label">
              <input
                type="radio"
                value="all-dates"
                name="period"
                checked={period === "all-dates"}
                onChange={this.handleRadios}
              />
              <span className="vg-label-text">
                {intl.get(`REQUISICAO_DE_VAGAS.${intlReplace(type)}.RADIO_1`)}
              </span>
            </label>
            <label className="vg-label">
              <input
                type="radio"
                value="personalized"
                name="period"
                checked={period === "personalized"}
                onChange={this.handleRadios}
              />
              <span className="vg-label-text">
                {intl.get(`REQUISICAO_DE_VAGAS.${intlReplace(type)}.RADIO_2`)}
              </span>
            </label>
          </fieldset>
          {period === "personalized" && (
            <div
              ref={(c) => {
                this.inputs = c;
              }}
              aria-live="polite"
            >
              {invalidDate && (
                <p className="vg-invalid-date">
                  {intl.get("VAGAS.BUSCA.DATA_INVALIDA")}
                </p>
              )}

              <div className="vg-label-date">
                <span>
                  {intl.get(
                    `REQUISICAO_DE_VAGAS.${intlReplace(type)}.SUB_TITULO.INICIO`
                  )}
                </span>
                <DatePicker
                  className="vg-input-date"
                  calendarClassName="vg-calendar"
                  selected={startDate}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  onChange={(date) => this.handleDate("startDate", date)}
                  shouldCloseOnSelect={true}
                  maxDate={endDate}
                />
              </div>
              <div className="vg-label-date">
                <span>
                  {intl.get(
                    `REQUISICAO_DE_VAGAS.${intlReplace(type)}.SUB_TITULO.FINAL`
                  )}
                </span>
                <DatePicker
                  className="vg-input-date"
                  calendarClassName="vg-calendar"
                  selected={endDate}
                  selectsEnd
                  startDate={startDate}
                  onChange={(date) => this.handleDate("endDate", date)}
                  shouldCloseOnSelect={true}
                  minDate={startDate}
                />
              </div>
            </div>
          )}
          <Link
            className="vg-btn-primary vg-btn-block"
            onClick={this.handleClick}
          >
            {intl.get("REQUISICAO_DE_VAGAS.BOTAO")}
          </Link>
        </div>
      </div>
    );
  }
}

SearchPeriod.defaultProps = {
  totalSelected: 0,
};

SearchPeriod.propTypes = {
  clickOutside: propTypes.func,
  onChange: propTypes.func,
  content: propTypes.array,
  type: propTypes.string.isRequired,
  i18n: propTypes.bool,
  insideLabel: propTypes.string,
  toggleFilter: propTypes.func.isRequired,
  activedFilters: propTypes.array.isRequired,
  initialValues: propTypes.object,
  parseFilter: propTypes.func.isRequired,
  filters: propTypes.object.isRequired,
};

export default connect(mapStateToProps, (dispatch) =>
  bindActionCreators({ toggleFilter, parseFilter }, dispatch)
)(onClickOutside(SearchPeriod));
