import React, { useState } from "react";
import {
  DropDownMenu,
  DropDownButtonList,
  Icon,
  RequisitionStatusModal,
} from "../../index";
import Buttons from "./Buttons";
import Status from "./Status";
import VincularVaga from "./VincularVaga";

import intl from "react-intl-universal";
import propTypes from "prop-types";

const VacanciesRequestItem = ({
  nome,
  dias,
  menu,
  status_aprovacao,
  vagaId,
  requisitante,
  responsavel,
  id,
  area,
  botoes,
}) => {
  const openWindow = (id) => {
    window.open(
      `/move-to/ver-requisicao?codigo_da_requisicao=${id}`,
      "x",
      "height=460,width=640,resizable=yes,status=no,scrollbars=yes,toolbar=yes,menubar=no,location=no"
    );
  };

  const [isOpenFlux, setIsOpenFlux] = useState(false);
  const [abrirVeicular, setAbrirVeicular] = useState(false);

  const toggleModal = () => {
    setIsOpenFlux(!isOpenFlux);
  };

  const toggleModalVeicular = () => {
    setAbrirVeicular(!abrirVeicular);
  };

  return (
    <li className="vg-list-item">
      <div className="vg-list-time">
        <Icon iconKey={dias == null ? "check" : "time"} aria-hidden={true} />
        <span className="vg-time-days">
          {dias == null
            ? intl.getHTML("REQUISICAO_DE_VAGAS.AUTO")
            : intl.getHTML("REQUISICAO_DE_VAGAS.QUANTIDADE_DIAS", {
                value: dias,
              })}
        </span>
      </div>
      <div className="vg-list-content">
        <div className="vg-list-content-title">
          <h2 className="vg-request-detail">
            <span className="vg-request-detail-name">{nome}</span>
            <span className="vg-request-detail-code">r{id}</span>
          </h2>
          <p className="vg-request-info">
            <span title={requisitante}>
              <Icon iconKey="briefcase" aria-hidden={true} />
              {requisitante}
              {!!area && `, ${area}`}
            </span>
            <span title={responsavel}>
              <Icon iconKey="userRegular" aria-hidden={true} />
              {responsavel}
            </span>
          </p>
        </div>
        <Status status={status_aprovacao} vagaId={vagaId} />
        <div className="vg-list-buttons">
          <Buttons
            botoes={botoes}
            popUp={() => openWindow(id)}
            toggleModal={() => toggleModal()}
            toggleModalVincular={() => toggleModalVeicular()}
            vagaId={vagaId}
            status_aprovacao={status_aprovacao}
            id={id}
          />
          {!!menu.length && (
            <DropDownMenu
              ariaLabel={intl.get("VAGAS.ACOES.BOTOES.MENU_REQUISICAO")}
            >
              <DropDownButtonList
                loadPopUp={(e) => {
                  e.preventDefault();
                  openWindow(id);
                }}
                typeMoveTo="codigo_da_requisicao"
                vagaId={vagaId}
                permissions={menu}
                id={id}
                toggleModal={() => toggleModal()}
                toggleModalVeicular={toggleModalVeicular}
              />
            </DropDownMenu>
          )}
        </div>
      </div>
      {isOpenFlux && (
        <RequisitionStatusModal
          toggleModal={() => toggleModal()}
          name={nome}
          showModal={isOpenFlux}
          requisitionId={id}
          status={status_aprovacao.status}
          requester={responsavel}
        />
      )}
      {abrirVeicular && (
        <VincularVaga
          toggleModalVeicular={() => toggleModalVeicular()}
          showModalVeicular={abrirVeicular}
          requisitionId={id}
        />
      )}
    </li>
  );
};

VacanciesRequestItem.defaultProps = {
  dias: 0,
  area: "",
};

VacanciesRequestItem.propTypes = {
  nome: propTypes.string.isRequired,
  dias: propTypes.number,
  botoes: propTypes.object.isRequired,
  menu: propTypes.array.isRequired,
  requisitante: propTypes.string.isRequired,
  responsavel: propTypes.string.isRequired,
  status_aprovacao: propTypes.object.isRequired,
  id: propTypes.number,
  vagaId: propTypes.number,
  area: propTypes.string,
};

export default VacanciesRequestItem;
