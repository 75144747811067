import "./FluxoStatus.scss";

import {
  FormatDate,
  Icon,
  Link,
  Modal,
  RequisitionStatusList,
  SplitText,
} from "../../index";
import React, { Component } from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import intl from "react-intl-universal";
import { intlReplace } from "../../../utils/IntlReplace";
import { load } from "../../../store/modules/VacanciesRequestStatus";
import propTypes from "prop-types";

const mapStateToProps = (state) => ({
  requestStatus: state.VacanciesRequestStatus.data,
  loading: state.VacanciesRequestStatus.loading,
  loaded: state.VacanciesRequestStatus.loaded,
});

class RequisitionStatusModal extends Component {
  componentDidMount() {
    const { load, requisitionId } = this.props;
    load(`requisicoes/${requisitionId}/fluxo_aprovacao`);
  }

  render() {
    const {
      requestStatus,
      name,
      loaded,
      loading,
      status,
      requester,
      requisitionId,
    } = this.props;

    return (
      <Modal
        show={this.props.showModal}
        onClose={this.props.toggleModal}
        small
        contentLabel={intl.get("REQUISICAO_DE_VAGAS.MODAL.INTRODUCAO.TITULO")}
      >
        <div className="vg-modal-body vg-modal-status">
          <p className="vg-requisition-name">
            <b>{name}</b>
          </p>
          {loading ? (
            <div className="animation-loader">
              <Icon iconKey="requesterPlaceholder" />
            </div>
          ) : !!loaded && Object.keys(requestStatus).length ? (
            <div>
              <p className="vg-requisition-name">
                {!!requestStatus.cabecalho.nome_em_copia && (
                  <span>
                    <b>
                      {intl.get(
                        "REQUISICAO_DE_VAGAS.MODAL.INTRODUCAO.EMAIL_EM_COPIA"
                      )}
                    </b>
                    {`${requestStatus.cabecalho.nome_em_copia}
                    ${requestStatus.cabecalho.email_em_copia} `}
                    {!!requestStatus.cabecalho
                      .alterar_usuario_aviso_automatico && (
                      <Link
                        className="vg-link"
                        moveTo="alterar_usuario_aviso_automatico"
                        params={{ codigo_da_requisicao: requisitionId }}
                      >
                        {intl.get(
                          "REQUISICAO_DE_VAGAS.MODAL.INTRODUCAO.alterar_usuario_aviso_automatico"
                        )}
                      </Link>
                    )}
                  </span>
                )}
              </p>
              <ul className="vg-requisition-status-list">
                <li
                  className="vg-requisition-status-item vg-requisition-status-item-initial"
                  style={{
                    zIndex: `${requestStatus.fluxo_aprovacao.length + 2}`,
                  }}
                >
                  <span className="vg-status-desc">
                    {intl.get("REQUISICAO_DE_VAGAS.MODAL.CRIACAO.TITULO")}
                  </span>
                  <p className="vg-status-name">
                    {requestStatus.pre_fluxo.nome_requisitante}
                    <span> ({requestStatus.pre_fluxo.email_requisitante})</span>
                  </p>
                  <span className="vg-status-date">
                    {intl.get("REQUISICAO_DE_VAGAS.MODAL.CRIACAO.DATA")}
                    <FormatDate
                      date={requestStatus.pre_fluxo.data_criacao_requisicao}
                      time={"REQUISICAO_DE_VAGAS.MODAL.CRIACAO.HORA"}
                    />
                  </span>
                </li>
                {requestStatus.pre_fluxo.pre_aprovacoes.length >= 1 && (
                  <li
                    className={`vg-requisition-status-item vg-requisition-status-item-pre vg-requisition-status-item-${requestStatus.pre_fluxo.pre_aprovacoes[0].status_aprovacao.replace(
                      /[_]/g,
                      "-"
                    )}`}
                    style={{
                      zIndex: `${requestStatus.fluxo_aprovacao.length + 1}`,
                    }}
                  >
                    <Icon
                      iconKey={
                        requestStatus.pre_fluxo.pre_aprovacoes[0]
                          .status_aprovacao
                      }
                    />
                    <span className="vg-status-desc">
                      {intl.get(
                        "REQUISICAO_DE_VAGAS.MODAL.PRE_APROVACAO.TITULO"
                      )}
                    </span>
                    <p className="vg-status-name">
                      {requestStatus.pre_fluxo.pre_aprovacoes[0].nome_aprovador}
                      <span>
                        {" "}
                        (
                        {
                          requestStatus.pre_fluxo.pre_aprovacoes[0]
                            .email_aprovador
                        }
                        )
                      </span>
                    </p>
                    {requestStatus.pre_fluxo.pre_aprovacoes[0]
                      .status_aprovacao === "aguardando_pre_aprovacao" ? (
                      <span className="vg-status-date">
                        {intl.get(
                          `REQUISICAO_DE_VAGAS.MODAL.PRE_APROVACAO.${intlReplace(
                            requestStatus.pre_fluxo.pre_aprovacoes[0]
                              .status_aprovacao
                          )}`
                        )}
                      </span>
                    ) : requestStatus.pre_fluxo.pre_aprovacoes[0]
                        .status_aprovacao === "aprovada_por_este_aprovador" ? (
                      <span>
                        <span className="vg-status-date">
                          {intl.get(
                            "REQUISICAO_DE_VAGAS.MODAL.STATUS_APROVACAO.PUBLICADA"
                          )}

                          <FormatDate
                            date={
                              requestStatus.pre_fluxo.pre_aprovacoes[0]
                                .data_avaliacao
                            }
                            time={"REQUISICAO_DE_VAGAS.MODAL.CRIACAO.HORA"}
                          />
                        </span>
                        <span className="vg-status-justification">
                          {!!requestStatus.pre_fluxo.pre_aprovacoes[0]
                            .justificativa && (
                            <SplitText
                              text={
                                requestStatus.pre_fluxo.pre_aprovacoes[0]
                                  .justificativa
                              }
                            />
                          )}
                        </span>
                      </span>
                    ) : (
                      requestStatus.pre_fluxo.pre_aprovacoes[0]
                        .status_aprovacao === "reprovada_pre_aprovacao" && (
                        <span>
                          <span className="vg-status-date">
                            {intl.get(
                              `REQUISICAO_DE_VAGAS.MODAL.PRE_APROVACAO.${intlReplace(
                                requestStatus.pre_fluxo.pre_aprovacoes[0]
                                  .status_aprovacao
                              )}`
                            )}
                            <FormatDate
                              date={
                                requestStatus.pre_fluxo.pre_aprovacoes[0]
                                  .data_avaliacao
                              }
                              time={"REQUISICAO_DE_VAGAS.MODAL.CRIACAO.HORA"}
                            />
                          </span>
                          {!!requestStatus.pre_fluxo.pre_aprovacoes[0]
                            .justificativa && (
                            <span className="vg-status-justification">
                              <b>
                                {intl.get(
                                  "REQUISICAO_DE_VAGAS.MODAL.CANCELADA.MOTIVO"
                                )}{" "}
                              </b>
                              {!!requestStatus.pre_fluxo.pre_aprovacoes[0]
                                .justificativa && (
                                <SplitText
                                  text={
                                    requestStatus.pre_fluxo.pre_aprovacoes[0]
                                      .justificativa
                                  }
                                />
                              )}
                            </span>
                          )}
                        </span>
                      )
                    )}
                  </li>
                )}
                <RequisitionStatusList
                  listStatus={requestStatus.fluxo_aprovacao}
                />
                {status === "aprovada_sem_vaga" ? (
                  <li className="vg-requisition-status-item vg-requisition-status-item-waiting vg-requisition-status-item-pos">
                    <span className="vg-status-desc">
                      {intl.get(
                        "REQUISICAO_DE_VAGAS.MODAL.POS_APROVACAO.PUBLICACAO"
                      )}
                    </span>
                    <p className="vg-status-name">{requester}</p>
                    <span className="vg-status-date">
                      {intl.get(
                        "REQUISICAO_DE_VAGAS.MODAL.POS_APROVACAO.AGUARDANDO"
                      )}
                    </span>
                    <Icon iconKey="aguardando_vaga" />
                  </li>
                ) : status === "aprovada_com_vaga" ? (
                  <li className="vg-requisition-status-item vg-requisition-status-item-with vg-requisition-status-item-pos">
                    <span className="vg-status-desc">
                      {intl.get(
                        "REQUISICAO_DE_VAGAS.MODAL.POS_APROVACAO.PUBLICACAO"
                      )}
                    </span>
                    <p className="vg-status-name">
                      {requestStatus.pos_aprovacao.nome_criador_vaga}
                      <span>
                        {" "}
                        ({requestStatus.pos_aprovacao.email_criador_vaga})
                      </span>
                    </p>
                    <span className="vg-status-date">
                      {intl.get("REQUISICAO_DE_VAGAS.MODAL.POS_APROVACAO.DATA")}
                      <FormatDate
                        date={requestStatus.pos_aprovacao.data_criacao_vaga}
                        time={"REQUISICAO_DE_VAGAS.MODAL.CRIACAO.HORA"}
                      />
                    </span>
                    <Icon iconKey="aprovada_com_vaga" aria-hidden={true} />
                  </li>
                ) : (
                  status === "cancelada" &&
                  !!requestStatus.canceladas.length && (
                    <li className="vg-requisition-status-item vg-requisition-status-item-canceled">
                      <span className="vg-status-desc">
                        {intl.get("REQUISICAO_DE_VAGAS.MODAL.CANCELADA.STATUS")}
                      </span>
                      <p className="vg-status-name">
                        {requestStatus.canceladas[0].nome_aprovador}
                      </p>
                      <span className="vg-status-date">
                        {intl.get("REQUISICAO_DE_VAGAS.MODAL.CANCELADA.DATA")}
                        <FormatDate
                          date={requestStatus.canceladas[0].data_avaliacao}
                          time={"REQUISICAO_DE_VAGAS.MODAL.CRIACAO.HORA"}
                        />
                      </span>
                      {!!requestStatus.canceladas[0].justificativa && (
                        <span className="vg-status-desc">
                          <b>
                            {intl.get(
                              "REQUISICAO_DE_VAGAS.MODAL.CANCELADA.MOTIVO"
                            )}
                          </b>
                          {requestStatus.canceladas[0].justificativa}
                        </span>
                      )}
                      <Icon iconKey="cancelada" />
                    </li>
                  )
                )}
              </ul>
            </div>
          ) : (
            <p>Error</p>
          )}
        </div>
      </Modal>
    );
  }
}

RequisitionStatusModal.propTypes = {
  carregarJustificativa: propTypes.number,
  load: propTypes.func,
  requisitionId: propTypes.number,
  requestStatus: propTypes.object,
  name: propTypes.string,
  loaded: propTypes.bool,
  loading: propTypes.bool,
  status: propTypes.string,
  requester: propTypes.string,
  showModal: propTypes.bool,
  toggleModal: propTypes.func,
};
export default connect(mapStateToProps, (dispatch) =>
  bindActionCreators({ load }, dispatch)
)(RequisitionStatusModal);
