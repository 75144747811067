import { Link } from "../../index";
import React from "react";
import intl from "react-intl-universal";
import { intlReplace } from "../../../utils/IntlReplace";
import { map } from "lodash";
import propTypes from "prop-types";
import shortid from "shortid";
import { openModal } from "../../../store/modules/ModalShareVideoInterview";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./DropDownMenu.scss";
import "./DropDownButtonList.scss";
import Context from "./context";

const DropDownButtonList = ({
  permissions,
  id,
  toggleModal,
  hideVacancy,
  typeMoveTo,
  vagaId,
  candidatoId,
  participanteId,
  loadPopUp,
  openModal,
  toggleModalVeicular,
}) => {
  const excludedPermissions = ["enviar-feedback", "indicar-contratados"];
  const permissionsToShow = permissions.filter(
    (perm) => !excludedPermissions.includes(perm)
  );
  const hasSeparator = [
    "triagem-1-0-vaga",
    "triagem-2-0-vaga",
    "novo-evento-presencial",
  ];

  return (
    <Context.Consumer>
      {({ closeSelf }) => (
        <ul className="vg-dropdown-list vg-dropdown-button-list" id="menu-vaga">
          {map(permissionsToShow, (permission) => {
            const liClassName = hasSeparator.includes(permission)
              ? "vg-line-top"
              : "";
            return permission === "fechar-vaga" ? (
              <li key={shortid.generate()} id="btn_fechar_vaga">
                <Link
                  to="#"
                  onClick={(el) => toggleModal(el, permission)}
                  data-testid={`menu-${permission}`}
                >
                  {intl.get(`VAGAS.ACOES.MENU.${intlReplace(permission)}`)}
                </Link>
              </li>
            ) : permission === "ver-fluxo-aprovacao" ? (
              <li key={shortid.generate()}>
                <Link
                  onClick={() => toggleModal()}
                  data-testid={`menu-${permission}`}
                >
                  {intl.get(`VAGAS.ACOES.MENU.${intlReplace(permission)}`)}
                </Link>
              </li>
            ) : permission === "ocultar-vaga" ? (
              <li key={shortid.generate()} className={liClassName}>
                <Link
                  to="#"
                  onClick={(el) => hideVacancy(el, id)}
                  data-testid={`menu-${permission}`}
                >
                  {intl.get(`VAGAS.ACOES.MENU.${intlReplace(permission)}`)}
                </Link>
              </li>
            ) : permission === "video-entrevista" ? (
              <li key={shortid.generate()} className={liClassName}>
                <Link
                  to={"/video-entrevista/" + id}
                  data-testid={`menu-${permission}`}
                >
                  {intl.get(`VAGAS.ACOES.MENU.${intlReplace(permission)}`)}
                </Link>
              </li>
            ) : permission === "ver-vaga" ? (
              <li key={shortid.generate()} className={liClassName}>
                <Link
                  moveTo={permission}
                  params={{ codigo_da_vaga: vagaId }}
                  data-testid={`menu-${permission}`}
                >
                  {intl.get(`VAGAS.ACOES.MENU.${intlReplace(permission)}`)}
                </Link>
              </li>
            ) : permission === "ver-requisicao" ? (
              <li key={shortid.generate()} className={liClassName}>
                <Link
                  moveTo={permission}
                  params={{ codigo_da_requisicao: id }}
                  onClick={(el) => loadPopUp(el, id)}
                  data-testid={`menu-${permission}`}
                >
                  {intl.get(`VAGAS.ACOES.MENU.${intlReplace(permission)}`)}
                </Link>
              </li>
            ) : permission === "curriculo-contexto-vaga" ? (
              <li key={shortid.generate()} className={liClassName}>
                <Link
                  moveTo="curriculo-contexto-vaga"
                  params={{
                    codigo_da_vaga: vagaId,
                    codigo_do_candidato: candidatoId,
                    codigo_da_video_entrevista: id,
                  }}
                  data-testid={`menu-${permission}`}
                >
                  {intl.getHTML("VIDEO_ENTREVISTA.EMPRESA.VIDEO.VER_CURRICULO")}
                </Link>
              </li>
            ) : permission === "compartilhamento-vaga" ? (
              <li key={shortid.generate()} className={liClassName}>
                <Link
                  onClick={() => {
                    closeSelf();
                    openModal(null, [participanteId]);
                  }}
                  data-testid={`menu-${permission}`}
                >
                  {intl.getHTML(
                    "VIDEO_ENTREVISTA.EMPRESA.VIDEO.COMPARTILHAMENTO"
                  )}
                </Link>
              </li>
            ) : permission === "publicar-vaga" ? (
              <li key={shortid.generate()} className={liClassName}>
                <Link
                  onClick={() => {
                    closeSelf();
                    toggleModalVeicular();
                  }}
                  data-testid={`menu-${permission}`}
                >
                  {intl.get(`VAGAS.ACOES.MENU.${intlReplace(permission)}`)}
                </Link>
              </li>
            ) : permission === "triagem-2-0-vaga" ? (
              <li key={shortid.generate()} className={liClassName}>
                <Link
                  moveTo="ver-candidaturas-triagem2"
                  params={{
                    codigo_da_vaga: id,
                  }}
                  data-testid={`menu-${permission}`}
                >
                  {intl.get(`VAGAS.ACOES.MENU.${intlReplace(permission)}`)}
                </Link>
              </li>
            ) : permission === "ver-candidatos-recomendados" ? null : (
              <li key={shortid.generate()} className={liClassName}>
                <Link
                  moveTo={permission}
                  params={
                    typeMoveTo === "codigo_da_requisicao"
                      ? { codigo_da_requisicao: id }
                      : { codigo_da_vaga: id }
                  }
                  data-testid={`menu-${permission}`}
                >
                  {intl.get(`VAGAS.ACOES.MENU.${intlReplace(permission)}`)}
                </Link>
              </li>
            );
          })}
        </ul>
      )}
    </Context.Consumer>
  );
};

DropDownButtonList.propTypes = {
  permissions: propTypes.array.isRequired,
  id: propTypes.number.isRequired,
  toggleModal: propTypes.func,
  hideVacancy: propTypes.func,
  typeMoveTo: propTypes.string,
  vagaId: propTypes.number,
  candidatoId: propTypes.number,
  participanteId: propTypes.number,
  loadPopUp: propTypes.func,
  openModal: propTypes.func,
  toggleModalVeicular: propTypes.func,
};

export default connect(null, (dispatch) =>
  bindActionCreators(
    {
      openModal,
    },
    dispatch
  )
)(DropDownButtonList);
