import propTypes from "prop-types";
import React from "react";
import intl from "react-intl-universal";
import {
  Link,
  Icon,
  DropDownMenu,
  DropDownButtonList,
  ListItemInfo,
  Tooltip,
  VacancyStatus,
} from "../../index";
import { intlReplace } from "../../../utils/IntlReplace";
import shortid from "shortid";
import { Avatar } from "@vagastec/epartner-ui";
import { addOpenPanelLinkDataLayer } from "../../../utils/dataLayerPush";

const ListItemHeader = (props) => {
  const {
    toggleModal,
    vacancyType,
    vacancy,
    pcd,
    id,
    menu,
    responsavel,
    hideVacancy,
    buttons,
    sortingDefault,
    grupo,
    status,
    isIntern,
    positions,
    featureFlags,
    panel,
    vagaInteligente,
  } = props;

  const moveToSorting =
    sortingDefault === "triagem_1"
      ? "ver-candidaturas-triagem1"
      : "ver-candidaturas-triagem2";

  const triagemV3 = menu.includes("triagem-3-0-vaga");

  function renderVacancyIcons(type, pcd) {
    return (
      <>
        {type === "programa_estagio_trainee" && <Icon iconKey="pet" />}
        {type === "programa_recrutamento_continuo" && <Icon iconKey="loop" />}
        {pcd && <Icon iconKey="pcd" />}
      </>
    );
  }

  function renderVacancyButtons(buttons, featureFlags) {
    if (!buttons) return;

    return (
      <>
        {triagemV3 && (
          <div className="badge-container">
            <div className="badge" aria-hidden="true">
              <box-icon
                aria-hidden
                type="solid"
                name="bell"
                color="#12B76A"
                data-icon="box-icon"
              />
              <span>
                {intl.get("CONFIGURACOES.CONTEUDO_ABAS.FORMULARIOS.badge")}
              </span>
            </div>
            <Link
              key={shortid.generate()}
              className="vg-btn-outline-light-gray"
              href={"/triagem/" + id}
              params={{ codigo_da_vaga: id }}
              data-testid={`link-nova-triagem-${id}`}
            >
              {intl.get("VAGAS.ACOES.BOTOES.NOVA_TRIAGEM")}
            </Link>
          </div>
        )}

        {buttons.includes("ver-candidaturas-triagem2") && (
          <Link
            key={shortid.generate()}
            className="vg-btn-outline-light-gray"
            moveTo={moveToSorting}
            params={{ codigo_da_vaga: id }}
          >
            {intl.get(
              `VAGAS.ACOES.BOTOES.${intlReplace("ver-candidaturas-triagem2")}`
            )}
          </Link>
        )}
        {buttons.includes("ver-candidaturas-triagem1") && (
          <Link
            key={shortid.generate()}
            className="vg-btn-outline-light-gray"
            moveTo={moveToSorting}
            params={{ codigo_da_vaga: id }}
          >
            {intl.get(
              `VAGAS.ACOES.BOTOES.${intlReplace("ver-candidaturas-triagem1")}`
            )}
          </Link>
        )}

        {buttons.includes("painel-vaga") &&
          featureFlags.includes("painel_vaga") && (
            <Link
              key={shortid.generate()}
              className="vg-btn-outline-light-gray"
              href={"/painel-vaga/" + id}
              onClick={() =>
                addOpenPanelLinkDataLayer({
                  companyId:
                    panel && panel.cliente
                      ? panel.cliente.id
                      : "valor-indisponivel",
                  companyName:
                    panel && panel.cliente
                      ? panel.cliente.nome
                      : "valor-indisponivel",
                  vacancyId: id || "valor-indisponivel",
                })
              }
              data-testid={`painel-${id}`}
            >
              {intl.get(`VAGAS.ACOES.BOTOES.${intlReplace("painel-vaga")}`)}
            </Link>
          )}
      </>
    );
  }

  return (
    <div className="vg-selective-process-list-header">
      <div className="vg-list-left-content">
        <div className="vg-item-avatar">
          <Avatar
            kind="image"
            name={responsavel.nome || ""}
            src={responsavel.avatar_url || ""}
          />
        </div>
        <div className="vg-detail">
          <div className="badge-rec-container">
            <h2 className="vg-name-vacancy">
              {renderVacancyIcons(vacancyType, pcd)}
              <Tooltip position="top" text={vacancy}>
                <span className="vg-title-vacancy">{vacancy}</span>
                <span className="vg-code-vacancy">v{id}</span>
              </Tooltip>
            </h2>
            {vagaInteligente && (
              <div className="badge-rec">
                <box-icon
                  name="bot"
                  color="#175CD3"
                  data-icon="box-icon"
                  aria-hidden="true"
                ></box-icon>
                <span>{intl.get("VAGAS.RECRUTAMENTO_INTL")}</span>
              </div>
            )}
            {grupo && !vagaInteligente ? (
              <span className="vg-name-group">{grupo}</span>
            ) : grupo ? (
              <Tooltip position="top" text={grupo}>
                <span className="vg-name-group group-tooltip">...</span>
              </Tooltip>
            ) : null}
          </div>
          <ListItemInfo {...props} />
        </div>
      </div>
      <div className="vg-middle-section">
        <VacancyStatus
          status={intlReplace(status)}
          isIntern={isIntern}
          closedPositions={positions.fechadas}
          id={id}
        />
      </div>
      <div className={`vg-list-buttons vg-list-buttons-${buttons.length}`}>
        {renderVacancyButtons(buttons, featureFlags)}
        <DropDownMenu
          cssClasses={["vg-btn-outline-light-gray"]}
          ariaLabel={intl.get("VAGAS.ACOES.BOTOES.MENU_VAGA")}
        >
          <DropDownButtonList
            hideVacancy={hideVacancy}
            permissions={menu}
            id={id}
            toggleModal={toggleModal}
          />
        </DropDownMenu>
      </div>
    </div>
  );
};

ListItemHeader.propTypes = {
  buttons: propTypes.array.isRequired,
  id: propTypes.number.isRequired,
  isIntern: propTypes.bool.isRequired,
  menu: propTypes.array.isRequired,
  pcd: propTypes.any,
  positions: propTypes.object.isRequired,
  responsavel: propTypes.object.isRequired,
  vacancy: propTypes.string.isRequired,
  vacancyType: propTypes.string.isRequired,
  hideVacancy: propTypes.func.isRequired,
  sortingDefault: propTypes.string.isRequired,
  toggleModal: propTypes.func.isRequired,
  status: propTypes.string,
  grupo: propTypes.string,
  featureFlags: propTypes.array,
  panel: propTypes.object.isRequired,
  vagaInteligente: propTypes.bool.isRequired,
};

export default ListItemHeader;
