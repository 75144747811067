import "./UserNavigation.scss";

import { Icon, Link } from "../../index";
import React, { Component } from "react";

import intl from "react-intl-universal";
import { intlReplace } from "../../../utils/IntlReplace";
import { map } from "lodash";
import onClickOutside from "react-onclickoutside";
import { toggleModalStatus } from "../../../store/modules/ModalAboutCredits";
import { load } from "../../../store/modules/Credits";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import propTypes from "prop-types";
import { vpv } from "../../../utils/VirtualPageView";

const mapStateToProps = (state) => ({
  credits: state.Credits.disponiveis,
});

class UserNavigation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isToggleOn: false,
    };

    this.toggleMenu = this.toggleMenu.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  toggleMenu(e) {
    e && e.preventDefault();

    this.setState((prevState) => ({
      isToggleOn: !prevState.isToggleOn,
    }));
  }

  componentDidMount() {
    const { load } = this.props;
    load("creditos-vagas/informacao");
    document.addEventListener("keydown", this.handleKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown);
  }

  handleClickOutside() {
    this.setState({ isToggleOn: false });
  }

  handleKeyDown(event) {
    if (event.key === "Escape" && this.state.isToggleOn) {
      this.setState({ isToggleOn: false });
    }
  }

  render() {
    const { username, navigation, isCredits, isVagas25 } = this.props;

    return (
      <div
        className={
          this.state.isToggleOn
            ? "vg-user-active vg-user-navigation"
            : "vg-user-navigation"
        }
      >
        <button
          type="button"
          className="vg-user-link"
          onClick={this.toggleMenu}
          aria-label={intl.get("NAVEGACAO.MENU_USER")}
          aria-controls="user-menu"
          aria-expanded={this.state.isToggleOn}
          aria-haspopup={true}
        >
          {username ? username.split(" ")[0] : ""}
          <Icon iconKey="arrow" />
        </button>
        <ul
          className="vg-user-menu"
          id="user-menu"
          role="true"
          aria-hidden={!this.state.isToggleOn}
        >
          {isCredits && !isVagas25 && (
            <React.Fragment>
              <li className="vg-item-credits vg-hide-md" role="menuitem">
                <Icon iconKey="dollar" />
                {intl.get("USERNAVIGATION.CREDITOS_DISPONIVEIS", {
                  credits: this.props.credits,
                })}
              </li>
              <li role="menuitem">
                <Link
                  onClick={() => vpv("/funil-compras/creditos-menu")}
                  moveTo="comprar-creditos-vagas"
                  className="vg-btn-success btn-comprar-creditos"
                >
                  {intl.get("USERNAVIGATION.COMPRAR_CREDITOS")}
                </Link>
              </li>
              <li className="vg-hide-md" role="menuitem">
                <Link
                  icon="entenda-creditos"
                  onClick={this.props.toggleModalStatus}
                >
                  {intl.get("USERNAVIGATION.ENTENDA_SEUS_CREDITOS")}
                </Link>
              </li>
              <li className="vg-hide-md vg-bottom-separator" role="menuitem">
                <Link icon="bx-detail" moveTo="servicos-contratados">
                  {intl.get("USERNAVIGATION.SERVICOS_CONTRATADOS")}
                </Link>
              </li>
            </React.Fragment>
          )}
          {map(navigation, (linkItem) => (
            <li key={`vg-user-link-${linkItem}`} role="menuitem">
              <Link moveTo={linkItem}>
                {intl.get(`USERNAVIGATION.${intlReplace(linkItem)}`)}
              </Link>
            </li>
          ))}
          <li role="menuitem">
            <Link moveTo="finalizar-sessao">
              {intl.get("USERNAVIGATION.LOGOFF")}
            </Link>
          </li>
        </ul>
      </div>
    );
  }
}

UserNavigation.defaultProps = {
  navigation: [],
  username: "",
  isCredits: false,
  isVagas25: false,
};

UserNavigation.propTypes = {
  navigation: propTypes.array.isRequired,
  username: propTypes.string.isRequired,
  toggleModalStatus: propTypes.func,
  isCredits: propTypes.bool,
  credits: propTypes.number.isRequired,
  load: propTypes.func,
  isVagas25: propTypes.bool,
};

export default connect(mapStateToProps, (dispatch) =>
  bindActionCreators({ toggleModalStatus, load }, dispatch)
)(onClickOutside(UserNavigation));
