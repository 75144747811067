import React, { Component } from "react";
import { Field } from "react-final-form";
import { includes, map } from "lodash";

import { Icon } from "../../index";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import intl from "react-intl-universal";
import { intlReplace } from "../../../utils/IntlReplace";
import onClickOutside from "react-onclickoutside";
import PropTypes from "prop-types";
import { toggleFilter } from "../../../store/modules/RequestFilter";
import InputFilter from "./InputFilter";

const mapStateToProps = (state, props) => ({
  totalSelected: state.RequestFilter.filter[props.type]
    ? state.RequestFilter.filter[props.type].filter((it) => it).length
    : 0,
  activedFilters: state.RequestFilter.activedFilters,
});

class FilterList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      loading: false,
    };

    this.filter = this.filter.bind(this);
    this.loading = this.loading.bind(this);
  }

  UNSAFE_componentWillMount() {
    const { content } = this.props;

    this.setState({
      items: content,
    });
  }

  prepareString(value = "") {
    return value
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
  }

  filter(value) {
    const { content } = this.props;

    const filtered = content.filter((obj) =>
      includes(this.prepareString(obj), this.prepareString(value))
    );

    this.setState({
      items: filtered,
    });
  }

  loading(bool) {
    this.setState({
      loading: bool,
    });
  }

  handleClickOutside() {
    const { toggleFilter, type, activedFilters } = this.props;
    activedFilters.includes(type) && toggleFilter(type);
  }

  render() {
    const {
      type,
      i18n,
      insideLabel,
      totalSelected,
      inside,
      activedFilters,
      toggleFilter,
      content,
    } = this.props;
    const { items, loading } = this.state;

    return (
      <div
        className={`vg-filter ${
          activedFilters.includes(type) && "vg-filter-open"
        } ${inside && "vg-filter-more"}`}
      >
        <button
          className="vg-filter-title"
          onClick={() => toggleFilter(type)}
          type="button"
          aria-controls="status-filter"
          aria-expanded={activedFilters.includes(type)}
        >
          {intl.get(`VAGAS.BUSCA.${intlReplace(type)}.TITULO`)}:
          <span className="vg-filter-smart-placeholder">
            {intl.getHTML("VAGAS.BUSCA.ITEM", { value: totalSelected })}
          </span>
          <Icon iconKey="arrow" />
        </button>
        <div className="vg-menu-filter" id="status-filter">
          <InputFilter
            content={content ? content.length : 0}
            filter={this.filter}
            type={type}
            loading={this.loading}
            ariaLabel={intl.get("VAGAS.BUSCA.ACCESSIBLE_NAME_STATUS")}
          />
          {!!insideLabel && (
            <label className="vg-label-title">{insideLabel}</label>
          )}
          <div className={`vg-filter-scroll ${loading && "vg-filter-loading"}`}>
            {map(items, (item) => (
              <div className="vg-custom-check" key={item}>
                <label className="vg-label">
                  <span className="vg-input-checkbox">
                    <Field
                      value={item}
                      name={type}
                      component="input"
                      type="checkbox"
                    />
                    <span className="vg-input-checkbox-inner" />
                  </span>
                  <span className="vg-label-text">
                    {i18n
                      ? intl.get(
                          `VAGAS.BUSCA.${intlReplace(type)}.${intlReplace(
                            item
                          )}`
                        )
                      : item}
                  </span>
                </label>
              </div>
            ))}
            {!items.length && (
              <div className="vg-custom-check" aria-live="polite">
                <label className="vg-label">
                  <span className="vg-label-text">Sem resultados</span>
                </label>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

FilterList.defaultProps = {
  totalSelected: 0,
  content: [],
  i18n: false,
};

FilterList.propTypes = {
  clickOutside: PropTypes.func,
  toggleFilter: PropTypes.func.isRequired,
  content: PropTypes.array,
  activedFilters: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
  i18n: PropTypes.bool,
  inside: PropTypes.bool,
  insideLabel: PropTypes.string,
  totalSelected: PropTypes.number,
};

export default connect(mapStateToProps, (dispatch) =>
  bindActionCreators({ toggleFilter }, dispatch)
)(onClickOutside(FilterList));
