import React, { Component } from "react";
import propTypes from "prop-types";
import intl from "react-intl-universal";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  searchFacets,
  inputHandler,
} from "../../../store/modules/RequestFilter";
import { debounce } from "lodash";

const mapStateToProps = (state, props) => ({
  inputs: state.RequestFilter.inputs[props.type]
    ? state.RequestFilter.inputs[props.type]
    : "",
});

class InputFilter extends Component {
  constructor(props) {
    super(props);

    this.searchAndFilter = this.searchAndFilter.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.search = debounce((value) => this.searchAndFilter(value), 500);
  }

  searchAndFilter(value) {
    const { filter, searchFacets, type, loading } = this.props;
    const parsedType = type.split("_id")[0];

    if (parsedType === "status") {
      filter(value);
      loading(false);
    } else {
      searchFacets(`/requisicoes/${parsedType}`, value, parsedType, {
        nome: value,
        tamanho: 100,
      })
        .then(() => {
          filter(value);
          loading(false);
        })
        .catch(() => {
          loading(false);
        });
    }
  }

  componentDidUpdate(prevProps) {
    const { filter } = this.props;
    if (prevProps.inputs !== this.props.inputs && this.props.inputs === "") {
      filter("");
    }
  }

  handleInput(value) {
    const { loading, inputHandler, type } = this.props;

    loading(true);
    inputHandler(type, value);
    this.search(value);
  }

  render() {
    const { content, inputs, ariaLabel } = this.props;

    return content > 6 ? (
      <form autoComplete="off" onSubmit={(e) => e.preventDefault()}>
        <input
          placeholder={intl.get("VAGAS.BUSCA.LABEL_PESQUISAR")}
          className="vg-input-text"
          type="text"
          name="byName"
          value={inputs}
          onChange={(el) => this.handleInput(el.target.value)}
          aria-label={ariaLabel}
        />
      </form>
    ) : null;
  }
}

InputFilter.defaultProps = {
  type: "",
  ariaLabel: "",
};

InputFilter.propTypes = {
  content: propTypes.number.isRequired,
  filter: propTypes.func.isRequired,
  searchFacets: propTypes.func.isRequired,
  inputHandler: propTypes.func.isRequired,
  type: propTypes.string,
  loading: propTypes.func.isRequired,
  inputs: propTypes.string,
  ariaLabel: propTypes.string,
};

export default connect(mapStateToProps, (dispatch) =>
  bindActionCreators({ searchFacets, inputHandler }, dispatch)
)(InputFilter);
