import React from "react";
import propTypes from "prop-types";
import { map } from "lodash";
import intl from "react-intl-universal";
import { withRouter } from "react-router";

import { Link, SubMenu } from "../../index";
import { intlReplace } from "../../../utils/IntlReplace";
import { headerDatalayer } from "../../../utils/dataLayerPush";
import "./Navigation.scss";

const Navigation = ({ accesses, userId, clientId, location, isVagas25 }) => {
  const eventGTM = (label) => {
    headerDatalayer(
      "interaction",
      label.replace(/\s/g, "-").toLowerCase(),
      userId
    );
  };

  // Trecho comentado até a V2 da vaga inteligente
  // const [showNotification, setShowNotification] = useState(false);

  // const [hasNotification, setHasNotification] = useState(
  //   info.some((item) => item.new)
  // );

  // useEffect(() => {
  //   if (showNotification && hasNotification) {
  //     setTimeout(() => {
  //       setHasNotification(false);
  //     }, "5000");
  //   }
  // }, [showNotification, hasNotification]);

  return (
    <nav className="vg-navigation">
      <ul>
        <li>
          <Link
            to="/"
            activeClassName="vg-link-active"
            exact
            onClick={() => eventGTM("inicio")}
            data-testid="link-home"
          >
            {intl.get("NAVEGACAO.HOME")}
          </Link>
        </li>
        {map(accesses, (access, index) => (
          <li key={index}>
            {access.label === "relatorios" ? (
              <Link
                moveTo={access.label}
                onClick={() => eventGTM(access.label)}
                data-testid="link-relatorios"
              >
                {intl.get(`NAVEGACAO.${intlReplace(access.label)}`)}
              </Link>
            ) : (
              <div>
                <Link
                  to={`/${access.label}`}
                  activeClassName="vg-link-active"
                  onClick={() => eventGTM(access.label)}
                  data-testid={`link-${access.label}`}
                >
                  {intl.get(`NAVEGACAO.${intlReplace(access.label)}`)}
                </Link>
                {access.submenu && (
                  <SubMenu
                    label={access.label}
                    menuItens={access.submenu}
                    userId={userId}
                    clientId={clientId}
                  />
                )}
              </div>
            )}
          </li>
        ))}
      </ul>
      <div>
        {location.pathname !== "/vagas" && !isVagas25 && (
          <a
            href={`${process.env.REACT_APP_ACCESS_CONTROL}/move-to/cadastrar-profissional?url_params=profissionais`}
            className="header-search-icon"
            aria-label={intl.get("NAVEGACAO.PESQUISA_CANDIDATOS")}
          >
            <box-icon name="search" aria-hidden />
          </a>
        )}
        {/* <Button
          data-testid="notification-button"
          className="header-notification-icon"
          onClick={() => setShowNotification(!showNotification)}
          aria-label={intl.get("VAGAS.NOTIFICACOES")}
        >
          <box-icon name="bell" color="#fff" aria-hidden />
          {hasNotification && <span className="vg-bell-ring" />}
        </Button>
        {showNotification && (
          <NotificationVagaIntl info={info} hasNotification={hasNotification} />
        )} */}
      </div>
    </nav>
  );
};

Navigation.propTypes = {
  accesses: propTypes.array.isRequired,
  userId: propTypes.number.isRequired,
  clientId: propTypes.number.isRequired,
  location: propTypes.object,
  isVagas25: propTypes.bool,
};

Navigation.defaultProps = {
  accesses: [],
  userId: 0,
};

export default withRouter(Navigation);
