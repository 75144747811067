import React, { Component } from "react";
import propTypes from "prop-types";
import intl from "react-intl-universal";
import {
  Button,
  FilterList,
  FilterListTop,
  Icon,
  RequestFilterPeriod,
} from "../../index";
import { Form, Field, FormSpy } from "react-final-form";
import { bindActionCreators } from "redux";
import { storeValues } from "../../../store/modules/RequestFilter";
import { connect } from "react-redux";
import { isEqual, debounce } from "lodash";

class RequestFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      values: {},
    };

    this.checkIsDiff = this.checkIsDiff.bind(this);
    this.submit = debounce(() => props.storeValues(this.state.values), 650);
  }

  componentDidMount() {
    const { initialValues } = this.props;

    this.setState({
      values: initialValues,
    });
  }

  checkIsDiff(values) {
    const difference = isEqual(values, this.state.values);

    if (!difference) {
      this.setState(
        {
          values,
        },
        this.submit
      );
    }
  }

  render() {
    const { facets, activedFilters, toggleFilter, initialValues } = this.props;
    const { responsaveis, requisitantes, status } = facets;

    return (
      <Form
        onSubmit={({ values }) => this.checkIsDiff(values)}
        initialValues={initialValues}
        render={() => (
          <div className="vg-search-form">
            <div className="vg-input-search">
              <Icon iconKey="search" aria-hidden />
              <Field
                name="q"
                id="q"
                component="input"
                placeholder={intl.get("REQUISICAO_DE_VAGAS.BUSCA.INPUT")}
                aria-label={intl.get(
                  "REQUISICAO_DE_VAGAS.BUSCA.ACCESSIBLE_NAME"
                )}
              />
            </div>
            <Button
              onClick={() => toggleFilter("all-filters")}
              className="vg-open-filter"
            >
              <Icon iconKey="filter" />
            </Button>
            <div
              className={`vg-all-filter ${
                activedFilters.includes("all-filters") && "vg-filter-open"
              }`}
            >
              {!!status && (
                <FilterList content={status["status"]} type="status" i18n />
              )}
              {!!responsaveis && (
                <FilterListTop
                  content={responsaveis["responsaveis"]}
                  type="responsaveis_id"
                  ariaLabel={intl.get(
                    "VAGAS.BUSCA.ACCESSIBLE_NAME_RESPONSIBLE"
                  )}
                />
              )}
              {!!requisitantes && (
                <FilterListTop
                  content={requisitantes["requisitantes"]}
                  type="requisitantes_id"
                  ariaLabel={intl.get("VAGAS.BUSCA.ACCESSIBLE_NAME_REQUESTER")}
                />
              )}
              <RequestFilterPeriod type="data_de_abertura" />
            </div>
            <FormSpy
              onChange={({ values }) => this.checkIsDiff(values)}
              subscription={{ values: true }}
            />
          </div>
        )}
      />
    );
  }
}

export default connect(undefined, (dispatch) =>
  bindActionCreators({ storeValues }, dispatch)
)(RequestFilter);

RequestFilter.propTypes = {
  facets: propTypes.object.isRequired,
  submitContent: propTypes.func.isRequired,
  toggleFilter: propTypes.func.isRequired,
  activedFilters: propTypes.array.isRequired,
  values: propTypes.any,
  passthroughValues: propTypes.object,
  initialValues: propTypes.object.isRequired,
  storeValues: propTypes.func.isRequired,
};
