import "./DashboardButton.scss";

import React, { useState } from "react";
import { array } from "prop-types";
import { Link } from "../../index";
import intl from "react-intl-universal";
import onClickOutside from "react-onclickoutside";

function DashboardButton({ featureFlags }) {
  const [isOpen, setIsOpen] = useState(false);

  function toggleIsOpen(e) {
    e.preventDefault();
    setIsOpen((prev) => !prev);
  }

  DashboardButton.handleClickOutside = () => {
    setIsOpen(false);
  };

  return (
    <div
      className={
        isOpen ? "vg-user-active vg-dashboard-button" : "vg-dashboard-button"
      }
    >
      <button
        className="vg-user-link"
        onClick={toggleIsOpen}
        aria-label={intl.get("NAVEGACAO.MENU")}
        aria-expanded={isOpen}
        aria-controls="menu-dashboard"
        aria-haspopup={true}
      >
        <box-icon name="grid" type="solid" color="white" aria-hidden />
      </button>
      <ul
        className="vg-user-menu"
        id="menu-dashboard"
        aria-hidden={!isOpen}
        role="menu"
      >
        <h3>{intl.get("NAVEGACAO.RECURSOS_ADICIONAIS")}</h3>
        <div className="row">
          {featureFlags.includes("link-marketing-place") && (
            <li role="menuitem">
              <Link to="/marketplace" target="_self">
                <span className="round-link">
                  <box-icon type="solid" name="widget"></box-icon>
                  <span className="round-link--badge-new">Novo</span>
                </span>
                <span className="label">
                  {intl.get("NAVEGACAO.MARKETPLACE")}
                </span>
              </Link>
            </li>
          )}
        </div>
      </ul>
    </div>
  );
}

DashboardButton.propTypes = {
  featureFlags: array,
};

const clickOutsideConfig = {
  handleClickOutside: () => DashboardButton.handleClickOutside,
};

export default onClickOutside(DashboardButton, clickOutsideConfig);
